<template>
  <div>
    <section id="page-title" class="page-title-parallax page-title-center page-title-dark include-header" style="background-image: linear-gradient(to top, rgba(254,150,3,0.5), #39384D), url('/assets/images/services/banner/customersupport.jpg'); background-size: cover; padding: 120px 0;" data-bottom-top="background-position:0px 300px;" data-top-bottom="background-position:0px -300px;">
        <div id="particles-line"></div>

        <div class="container clearfix mt-4">
            <div class="badge badge-pill border border-light text-light">Customer Experience</div>
            <h1>Customer Success</h1>
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
                <li class="breadcrumb-item active" aria-current="page">Customer Success</li>
            </ol>
        </div>

    </section>
    <section id="content">

			<div class="content-wrap pb-0">
                <!-- Main Content
				============================================= -->
				<div class="container">
					<div class="row align-items-end">
						<div class="col-lg-6">
							<div class="badge badge-pill badge-default">Customer Success</div>
							<h2 class="display-4 font-weight-bold">Empowering Customer Success Specialists.</h2>
							<p>
                                Partnerships are in the eFlex DNA. Our customer success specialists are proactive in engaging with your partners to achieve intended outcomes for business growth. We understand the importance of customer success and customer experience in preventing churn. Our objective is to make sure that your users have a great experience with your product to ensure the longevity of their membership.
                            </p>
							<router-link to="/contact"><a class="button button-rounded button-large nott ls0">Get Started</a></router-link>
						</div>
						<div class="col-lg-6 mt-4 mt-lg-0">
							<img src="/assets/images/services/header/customersupport.jpg" alt="Image">
						</div>
					</div>
				</div>
				<!-- Features
				============================================= -->
				<div class="section mb-0">
					<div class="container">
						<div class="heading-block border-bottom-0 center">
							<div class="badge badge-pill badge-default">Services</div>
							<h3 class="nott ls0">What We Can Do</h3>
							<p>Our customer success specialists serve as your internal advocates and partner liaisons. We are driven to own overall relationships with assigned partners including managing onboarding and implementation of high levels of customer satisfaction. We help to consistently provide value of your products and services for your partners.</p>
						</div>
						<div class="row col-mb-30 align-content-stretch">

							<div class="col-lg-4 col-md-6 d-flex">
								<div class="card">
									<div class="card-body p-5">
										<div class="feature-box flex-column">
											<div class="mb-3">
												<img src="/assets/images/services/pagecards/customersuccess1.jpg" alt="Feature Icon" class="bg-transparent rounded-0">
											</div>
											<div class="fbox-content">
												<h3 class="nott ls0 text-larger">Planning and Onboarding</h3>
												<p>
                                                    Our specialists ensure your partners reach their goals by using your product or service. We identify expectations and create processes to meet intended goals and create strategic steps so your users get started and stay engaged. We send reminders and motivators to incorporate your product into your partner' routines.
                                                </p>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="col-lg-4 col-md-6 d-flex">
								<div class="card">
									<div class="card-body p-5">
										<div class="feature-box flex-column">
											<div class="mb-3">
												<img src="/assets/images/services/pagecards/customersuccess2.jpg" alt="Feature Icon" class="bg-transparent rounded-0">
											</div>
											<div class="fbox-content">
												<h3 class="nott ls0 text-larger">Upsell / Cross-sell</h3>
												<p>
                                                    We are efficient in identifying up-sell or cross-sell opportunities to grow your current partners. We systematically look into usage data and customer feedback scores to make informed recommendations that are a 100% match with what your partners want to achieve. We help create a customer experience built on that trust.
                                                </p>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="col-lg-4 col-md-6 d-flex">
								<div class="card">
									<div class="card-body p-5">
										<div class="feature-box flex-column">
											<div class="mb-3">
												<img src="/assets/images/services/pagecards/customersuccess3.jpg" alt="Feature Icon" class="bg-transparent rounded-0">
											</div>
											<div class="fbox-content">
												<h3 class="nott ls0 text-larger">Customer Health Metrics</h3>
												<p>
                                                    Our customer success specialists track key metrics to maintain a pulse of customer health and satisfaction. From product usage, product adoption, to customer engagement, we can help identify pain points in your partner's customer journey to help improve overall customer health.
                                                </p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Contact/>
			</div>
	</section>
  </div>
</template>

<script>
// @ is an alias to /src
import Contact from '@/components/ContactUs.vue'

export default {
	name: 'CustomerSuccess',
	data() {
		return {

		}
	},
	components: {
		Contact
	},
	computed: {
		
	},
	methods: {
		
	},
	mounted() {
		window.scrollTo(0, 0)
	}
}
</script>
